/* eslint-disable */
import store from '../store/store'

export default {
  methods: {
    StarWebPrintTrader() {},
    sendMessage(a) {
      var _json = '';
      var _url = '';

      var b = "<root papertype=\"normal\">" + a.request + "</root>";
      var c = '<StarWebPrint xmlns="http://www.star-m.jp" xmlns:i="http://www.w3.org/2001/XMLSchema-instance"><Request>';
      c += this._encodeEscapeSequence(b);
      c += "</Request>";
      c += "</StarWebPrint>";
      b = "";
      b = a.url;
      if(/^https?:\/\/(localhost|127\.0\.0\.1):8001\//.test(b.toLowerCase()) && -1 != navigator.userAgent.indexOf("webPRNTSupportMessageHandler")) {
        _json = JSON.stringify({ url: b, body: c });
        _url = b;
        this._callMessageHandler(_url, _json);
      } else {
        var d = null;
        if(window.XMLHttpRequest) {
          d = new XMLHttpRequest;
        } else if(window.ActiveXObject) {
          d = new ActiveXObject("Microsoft.XMLHTTP");
        } else {
          return;
        }
        if (-1 != navigator.userAgent.indexOf("iPad;") || -1 != navigator.userAgent.indexOf("iPhone;") || -1 != navigator.userAgent.indexOf("iPod touch;") || -1 != navigator.userAgent.indexOf("Android")) if (-1 == navigator.userAgent.indexOf("WebPRNTSupportHTTPS") &&
          (0 == b.toLowerCase().indexOf("https://localhost") || 0 == b.toLowerCase().indexOf("https://127.0.0.1"))) {
          b = "http://" + b.substring(8);
        }
        try{
          d.open("POST", b, !0);
        } catch(f) {
          return;
        }
        try{
          d.timeout = 9E4;
        } catch(h) {}
        d.setRequestHeader("Content-Type", "text/xml; charset=UTF-8");
        d.onreadystatechange = function() {
          if(4 == d.readyState) {
            try{
              if(200 == d.status) {
                var a = d.responseXML.getElementsByTagName("Response")[0].childNodes[0].nodeValue
                var traderStatus = a.slice(a.indexOf("<status>") + 8, a.indexOf("</status>"))

                if (parseInt(traderStatus.substr(4, 2), 16) & 32) {
                  store.commit('viewstate/printerError', 'Printer cover is open!')
                } else if (parseInt(traderStatus.substr(4, 2), 16) & 8) {
                  store.commit('viewstate/printerError', 'Printer is offline!')
                } else if(parseInt(traderStatus.substr(4, 2), 16) & 4) {
                  store.commit('viewstate/printerError', 'Compulsion switch closed!')
                } else if(parseInt(traderStatus.substr(4, 2), 16) & 2) {
                  store.commit('viewstate/printerError', 'Etb Command Execute!')
                } else if(parseInt(traderStatus.substr(6, 2), 16) & 64) {
                  store.commit('viewstate/printerError', 'Temperatuur te hoog!')
                } else if(parseInt(traderStatus.substr(6, 2), 16) & 32) {
                  store.commit('viewstate/printerError', 'Non Recoverable Error!')
                } else if(parseInt(traderStatus.substr(6, 2), 16) & 8) {
                  store.commit('viewstate/printerError', 'Auto Cutter Error!')
                } else if(parseInt(traderStatus.substr(8, 2), 16) & 8) {
                  store.commit('viewstate/printerError', 'Black Mark Error!')
                } else if(parseInt(traderStatus.substr(10, 2), 16) & 8) {
                  store.commit('viewstate/printerError', 'Papier op!')
                } else {
                  store.commit('viewstate/printerError', null)
                }
              }
            } catch(c) {}
          }
        }
        try {
          d.ontimeout = function() {}
        } catch(k) {}
        try {
          d.send(c);
        } catch(g) {}
      }
    },
    _callMessageHandler(_url, _json) {
      //_handlerCallback[_url] == null ? (_handlerCallback[_url])
      webkit.messageHandlers.sendMessageHandler.postMessage(_json)
    },
    _encodeEscapeSequence(a) {
      var b = /[<>&]/g;
      b.test(a) && (a = a.replace(b, function(a) {
        switch (a) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;"
        }
        return "&amp;"
      }));
      return a;
    }
  }
}
