/* eslint-disable */
export default {
  methods: {
    StarWebPrintBuilder () {},
    _analysisEnumAttribute  (b, a, d) {
      if (void 0 != a) {
        if (!d.test(a)) throw Error('Argument "' + b + '" is invalid.');
        return " " + b + '="' + a + '"'
      }
      return ""
    },
    _analysisValueAttribute (b, a, d, e) {
      if (void 0 != a) {
        if (a < d || a > e) throw Error('Argument "' + b + '" is invalid.');
        return " " + b + '="' + a + '"'
      }
      return ""
    },
    _encodeEscapeSequence (b) {
      var a = /[\\\x00-\x20\x26\x3c\x3e\x7f]/g;
      a.test(b) && (b = b.replace(a, function (a) {
        return "\\" == a ? "\\\\" : "\\x" + ("0" + a.charCodeAt(0).toString(16)).slice(-2)
      }));
      return b
    },
    _encodeEscapeSequenceBinary (b) {
      var a = /[\\\x00-\x20\x26\x3c\x3e\x7f-\xff]/g;
      a.test(b) && (b = b.replace(a, function (a) {
        return "\\" == a ? "\\\\" : "\\x" + ("0" + a.charCodeAt(0).toString(16)).slice(-2)
      }));
      return b
    },
    createInitializationElement (b) {
      var a = "<initialization";
      void 0 != b && (a += this._analysisEnumAttribute("reset", b.reset, /^(false|true)$/), a += this._analysisEnumAttribute("print", b.print, /^(false|true)$/));
      return a + "/>"
    },
    createTextElement (b) {
      var a;
      if (void 0 != b) a = "<text" + this._analysisEnumAttribute("emphasis", b.emphasis, /^(false|true)$/), a += this._analysisEnumAttribute("invert", b.invert, /^(false|true)$/), a += this._analysisEnumAttribute("linespace", b.linespace, /^(24|32)$/), a += this._analysisEnumAttribute("font", b.font, /^(font_[ab])$/), a += this._analysisEnumAttribute("underline", b.underline, /^(false|true)$/), a += this._analysisValueAttribute("characterspace", b.characterspace, 0, 7), a +=
        this._analysisValueAttribute("width", b.width, 1, 6), a += this._analysisValueAttribute("height", b.height, 1, 6), a += this._analysisEnumAttribute("codepage", b.codepage, /^(cp(437|737|772|774|851|852|855|857|858|860|861|862|863|864|865|866|869|874|928|932|998|999|1001|1250|1251|1252|2001|3001|3002|3011|3012|3021|3041|3840|3841|3843|3844|3845|3846|3847|3848)|utf8|blank|utf8|shift_jis|gb18030|gb2312|big5|korea)$/), a += this._analysisEnumAttribute("international", b.international, /^(usa|france|germany|uk|denmark|sweden|italy|spain|japan|norway|denmark2|spain2|latin_america|korea|ireland|legal)$/),
        void 0 != b.data ? (a += ">", a = !0 == b.binary ? a + this._encodeEscapeSequenceBinary(b.data) : a + this._encodeEscapeSequence(b.data), a += "</text>") : a += "/>"; else throw Error("Argument is undefined.");
      return a
    },
    createAlignmentElement (b) {
      var a = "<alignment";
      void 0 != b && (a += this._analysisEnumAttribute("position", b.position, /^(left|center|right)$/));
      return a + "/>"
    },
    createCutPaperElement (b) {
      var a = "<cutpaper";
      void 0 != b && (a += this._analysisEnumAttribute("feed", b.feed, /^(false|true)$/), a += this._analysisEnumAttribute("type", b.type, /^(full|partial)$/));
      return a + "/>"
    },
  },
}
