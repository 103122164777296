<template>
  <v-container
    v-if="order"
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row
      align="end"
      justify="end"
    >
      <v-btn
        primary
        color="green"
        @click="printOrder"
      >
        {{ $t('button.print_receipt') }}
      </v-btn>
    </v-row>
    <v-row
      align="end"
      justify="end"
      class="error-message"
    >
      {{ printerError }}
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('order.order') }} {{ order.order_number }}
              <span class="body-1">— {{ $t('order.information') }}</span>
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$t('order.order_number')"
                    disabled
                    :value="order.order_number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$t('order.delivery_date')"
                    :disabled="order.delivery_date_final"
                    :value="order.delivery_date | datetime"
                    append-icon="mdi-pencil"
                    @click="openDeliveryDialog()"
                  />

                  <v-dialog
                    v-model="dialog"
                    max-width="400"
                    @click:outside="finish"
                  >
                    <v-card>
                      <v-card-title>
                        <span> {{ $t('order.change_delivery_date') }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-datetime-picker
                          v-model="currentDeliveryDate"
                          :label="$t('order.change_delivery_date')"
                          date-format="dd-MM-yyyy"
                          :date-picker-props="datePickerProps"
                          :time-picker-props="timePickerProps"
                        >
                          <template
                            slot="dateIcon"
                          >
                            <v-icon>mdi-calendar</v-icon>
                          </template>
                          <template
                            slot="timeIcon"
                          >
                            <v-icon>mdi-clock</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn
                          :disabled="!currentDeliveryDate"
                          class="mr-3"
                          color="primary"
                          text
                          @click="updateDeliveryDate(true)"
                        >
                          {{ this.$t('button.make_definitive') }}
                        </v-btn>
                        <v-btn
                          :disabled="!currentDeliveryDate"
                          class="mr-3"
                          color="primary"
                          text
                          @click="updateDeliveryDate(false)"
                        >
                          {{ this.$t('button.save') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$t('order.finished_on')"
                    disabled
                    :value="order.date_completed ? (order.date_completed | datetime) : null"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$t('order.customer_name')"
                    disabled
                    :value="order.customer_name"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-text-field
                    :label="$t('order.customer_note')"
                    disabled
                    :value="order.customer_note"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card icon="mdi-cash">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('order.payment_information') }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    :label="$t('order.payment_method')"
                    disabled
                    :value="order.payment_method"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    :label="$t('order.currencies')"
                    disabled
                    :value="order.currency"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    :label="$t('order.total_payment')"
                    disabled
                    :value="order.total | currency"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    :label="$t('order.btw')"
                    disabled
                    :value="order.vat | currency"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card icon="mdi-baguette">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('order.products') }}
            </div>
          </template>
          <v-data-table
            :headers="headers"
            :items="order.order_items"
            :search.sync="search"
            :items-per-page="5"
            :sort-desc="[false, true]"
            multi-sort
          >
            <template
              v-slot:item.price="{ item }"
            >
              {{ item.price | currency }}
            </template>
            <template
              v-slot:item.total="{ item }"
            >
              {{ item.total | currency }}
            </template>
            <template
              v-slot:item.order_item_attributes="{ item }"
            >
              <tr
                v-for="order_item_attribute in item.order_item_attributes"
                :key="order_item_attribute.name"
              >
                {{ order_item_attribute.name }}: <b>{{ order_item_attribute.value }}</b>
              </tr>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.application_status === 'new'"
                large
                class="mr-4"
                @click="takeOrderItemInProduction(item)"
              >
                mdi-play
              </v-icon>
              <v-icon
                v-if="item.application_status === 'in_production'"
                large
                class="mr-4"
                @click="finalizeOrderItem(item)"
              >
                mdi-check
              </v-icon>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <modal name="receipt-modal" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import WebPrintBuilder from '../../mixins/WebPrintBuilder'
  import WebPrintTrader from '../../mixins/WebPrintTrader'
  import moment from 'moment'

  export default {
    name: 'OrderOverview',
    mixins: [WebPrintBuilder, WebPrintTrader],
    data () {
      return {
        order: null,
        search: undefined,
        dialog: false,
        currentDeliveryDate: null,
        timePickerProps: {
          format: '24hr',
          color: 'primary',
        },
        datePickerProps: {
          locale: this.$vuetify.lang.current,
          color: 'primary',
        },
        printerError: null,
      }
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('order.headers.amount'),
            value: 'quantity',
          },
          {
            text: this.$t('order.headers.status'),
            value: 'application_status',
          },
          {
            text: this.$t('order.headers.product'),
            value: 'product_name',
          },
          {
            text: this.$t('order.headers.price'),
            value: 'price',
          },
          {
            text: this.$t('order.headers.total'),
            value: 'total',
          },
          {
            text: this.$t('order.headers.extra_information'),
            value: 'order_item_attributes',
          },
          {
            sortable: false,
            text: this.$t('order.headers.actions'),
            value: 'actions',
          },
        ]
      },
    },
    async mounted () {
      const orderId = this.$route.params.id
      let order = this.$store.getters['order/orders'].filter(item => item.id === orderId)[0]
      if (order === undefined) {
        await this.$store
          .dispatch('order/getOrdersV1', {
            id: orderId,
          })
          .then(response => {
            order = response.data
          })
          .catch(response => {
            this.$store.commit('viewstate/notify', {
              color: 'error',
              text:
                'Orders kon niet gevonden worden!',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
      this.order = order
    },
    methods: {
      takeOrderItemInProduction (orderItem) {
        this.$store.commit('viewstate/loader', {
          title: 'Wordt in productie genomen...',
          status: 'loading',
        })
        this.$store.dispatch('order/putOrderItemV1', {
          id: orderItem.id,
          body: {
            application_status: 'in_production',
          },
        }).then(response => {
          this.order = response.data
          this.$store.commit('viewstate/loader', {
            title: 'Succesvol in productie genomen',
            status: 'succeeded',
          })
        })
          .catch(response => {
            this.$store.commit('viewstate/loader', {
              title: 'Order kon niet in productie genomen worden!',
              status: 'failed',
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
      finalizeOrderItem (orderItem) {
        this.$store.commit('viewstate/loader', {
          title: 'Wordt afgerond...',
          status: 'loading',
        })
        this.$store.dispatch('order/putOrderItemV1', {
          id: orderItem.id,
          body: {
            application_status: 'done',
          },
        }).then(response => {
          this.order = response.data
          this.$store.commit('viewstate/loader', {
            title: 'Succesvol afgerond',
            status: 'succeeded',
          })
        })
          .catch(response => {
            this.$store.commit('viewstate/loader', {
              title: 'Order kon niet afgerond worden!',
              status: 'failed',
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
      openDeliveryDialog () {
        this.currentDeliveryDate = new Date(this.order.delivery_date)
        this.dialog = true
      },
      updateDeliveryDate (final = false) {
        const date = new Date(this.currentDeliveryDate)
        const body = {
          delivery_date: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
          final: final,
        }
        this.$store.dispatch('order/putDeliveryDateV1', {
          id: this.order.id,
          body: body,
        })
          .then(response => {
            this.order.delivery_date_final = final
            this.order.delivery_date = this.currentDeliveryDate
          })
          .finally(() => {
            this.finish()
          })
      },
      finish () {
        this.dialog = false
        this.currentDeliveryDate = null
      },
      printOrder () {
        var printerIp = this.$store.getters['application/configuration'] !== null ? this.$store.getters['application/configuration'].printer_ip_address : null
        var request = ''
        if (printerIp != null && printerIp !== '') {
          WebPrintBuilder.methods.StarWebPrintBuilder()
          request += WebPrintBuilder.methods.createInitializationElement()
          request += WebPrintBuilder.methods.createTextElement({ characterspace: 0 })
          request += WebPrintBuilder.methods.createAlignmentElement({ position: 'center' })
          request += WebPrintBuilder.methods.createTextElement({ data: 'Order #' + this.order.order_number + ' ' + this.order.customer_name + '\n\n' })

          if (this.order.customer_note) {
            request += WebPrintBuilder.methods.createTextElement({ emphasis: true })
            request += WebPrintBuilder.methods.createTextElement({ data: 'Notitie\n' })
            request += WebPrintBuilder.methods.createTextElement({ emphasis: false })
            request += WebPrintBuilder.methods.createTextElement({ data: this.order.customer_note + '\n\n' })
          }

          request += WebPrintBuilder.methods.createTextElement({ emphasis: true })
          request += WebPrintBuilder.methods.createTextElement({ data: 'Bezorgtijd\n' })
          request += WebPrintBuilder.methods.createTextElement({ emphasis: false })
          request += WebPrintBuilder.methods.createTextElement({ data: moment(String(this.order.delivery_date)).format('DD-MM-YYYY HH:mm') + '\n\n' })

          request += WebPrintBuilder.methods.createAlignmentElement({ position: 'left' })
          request += WebPrintBuilder.methods.createTextElement({ emphasis: true })
          request += WebPrintBuilder.methods.createTextElement({ data: 'Producten\n' })
          request += WebPrintBuilder.methods.createTextElement({ emphasis: false })

          this.order.order_items.forEach((orderItem, index) => {
            request += WebPrintBuilder.methods.createTextElement({ data: orderItem.quantity + 'x ' + orderItem.product_name + '\n' })
            orderItem.order_item_attributes.forEach((orderItemAttribute, index) => {
              request += WebPrintBuilder.methods.createTextElement({ data: orderItemAttribute.name + ': ' + orderItemAttribute.value + '\n' })
            })
            request += WebPrintBuilder.methods.createTextElement({ underline: true, data: '                        \n' })
            request += WebPrintBuilder.methods.createTextElement({ underline: false })
          })

          request += WebPrintBuilder.methods.createTextElement({ characterspace: 0 })
          request += WebPrintBuilder.methods.createCutPaperElement({ feed: true, type: 'partial' })

          // TODO: printer errors afvangen
          WebPrintTrader.methods.StarWebPrintTrader()
          WebPrintTrader.methods.sendMessage({ request: request, url: 'https://' + printerIp + '/StarWebPRNT/SendMessage' })

          this.printerError = this.$store.getters['viewstate/printerError']
        } else {
          let text = '<div style="padding:10px;"><div style="text-align:center;"><br/>Order #' + this.order.order_number + ' ' + this.order.customer_name
          if (this.order.customer_note) {
            text += '<br/><br/><b>Notitie</b><br/>' + this.order.customer_note
          }
          text += '<br/><br/><b>Bezorgtijd</b><br/>' + moment(String(this.order.delivery_date)).format('DD-MM-YYYY HH:mm')
          text += '</div><br/><br/><b>Producten</b><br/>'
          this.order.order_items.forEach((orderItem, index) => {
            text += orderItem.quantity + 'x ' + orderItem.product_name + '<br/>'
            orderItem.order_item_attributes.forEach((orderItemAttribute, index) => {
              text += orderItemAttribute.name + ': ' + orderItemAttribute.value + '<br/>'
            })
            text += '<hr />'
          })
          text += '</div>'
          this.$modal.show(
            {
              template: text,
            },
          )
        }
      },
    },
  }
</script>

<style>
  .error-message {
    color: red;
    margin-right: 17px;
  }
</style>
